import { ReactNode } from 'react';
import { CollectionSortFilters, SortTypes } from '@neo1/core/utils/collections';
import { IconName } from 'components/elements/Icon';
import { ButtonKind, ButtonTheme } from 'components/elements/Button';

export type TableColumn<T extends Object = Object> = {
  title?: string;
  prop: string;
  default?: string;
  sortProp?: string;
  getSortValue?: (record?: T) => string | number;
  style?: {
    alignment?: 'left' | 'center' | 'right';
    important?: boolean;
    width?: string;
    className?: string;
  };
  minWidth?: number;
  render?(
    value: any,
    record?: T,
    column?: TableColumn<T>,
    records?: T[],
  ): ReactNode;
  renderCSV?(value: any, record?: T, column?: TableColumn<T>): string;
  info?: ReactNode;
};

export type TableColumns<T extends Object = Object> = Array<TableColumn<T>>;

export type TableRowAction = {
  name: string;
  title: string;
  icon: IconName;
  onClick(): void;
  isDisabled?: boolean;
  isLoading?: boolean;
  theme?: ButtonTheme;
  kind?: ButtonKind;
  testId?: string;
};

export type TableRowActions = Array<TableRowAction>;

export enum TableRowStatus {
  normal = 'normal',
  highlight = 'highlight',
  error = 'error',
  warning = 'warning',
}

export enum TableHeadTheme {
  normal = 'normal',
  minimal = 'minimal',
  invisible = 'invisible',
}

export type TableExportAction = {
  id: string;
  icon: IconName;
  text: string;
  onClick(): void;
};

export type TableExportActions = Array<TableExportAction>;

export type SortFunction<T extends Object = Object> = (
  rows: T[],
  sort: CollectionSortFilters,
  getSortValue?: (row: T) => string | number,
) => T[];

export type OnSortChange = (prop: string, dir: SortTypes) => void;

export type OnRowClick<T extends Object = Object> = (item: T) => void;

export type GetRowActions<T extends Object = Object> = (
  item: T,
  index?: number,
) => TableRowActions;

export type GetRowStatus<T extends Object = Object> = (
  item: T,
) => TableRowStatus;

export type TableProps<T extends Object = Object> = {
  columns: TableColumns<T>;
  name: string;
  rows: Array<T>;
  rowKeyProp?: PropertyKey;
  sort?: CollectionSortFilters;
  // TODO Deprecated, use table column prop getSortValue
  sortFunction?: SortFunction<T>;
  onSortChange?: OnSortChange;
  onRowClick?: OnRowClick<T>;
  getRowActions?: GetRowActions<T>;
  getRowStatus?: GetRowStatus<T>;
  className?: string;
  exportActions?: TableExportActions | null;
  alwaysDisplayActions?: boolean;
  testId?: string;
  rowDetail?: {
    render: (row: T) => ReactNode;
    exportCsv?: (row: T) => Record<string, string | number>;
    toggledRowsByDefault?: string[];
    isOpenByDefault?: boolean; // If this is true, all rows will be opened by default and toggledRowsByDefault will contain closed rows
  };
  hideTableHead?: boolean;
  isRowSelectable?: (row: T) => boolean;
  hideSelectCheckbox?: boolean;
  headTheme?: TableHeadTheme;
};
