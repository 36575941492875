import { getClient } from '@neo1/client/lib/rpc/client';
import { makePayload } from '@neo1/client/lib/rpc/utils';
import { UUID } from '../common';
import {
  TaxConfig,
  TaxValues,
  DefaultTaxesConfig,
  ManualTaxConfig,
} from './types';
import { normalizeTax, normalizeTaxConfig } from './utils';

export const getCompanyTaxes = async (companyId: string) => {
  const result = await getClient().sendCommand({
    method: 'get_taxes',
    params: { company_id: companyId },
  });
  return result.map(normalizeTax);
};

export const deleteCompanyTax = (companyId: string, code: string) =>
  getClient().sendCommand({
    method: 'del_tax',
    params: {
      company_id: companyId,
      tax_code: code,
    },
  });

export const setCompanyTax = async (
  { taxCode, taxRate, description }: TaxValues,
  companyId: string,
) => {
  const result = await getClient().sendCommand({
    method: 'set_tax',
    params: makePayload({
      company_id: companyId,
      tax_code: taxCode,
      tax_rate: taxRate,
      description,
    }),
  });
  return normalizeTax(result);
};

export const setTaxConfiguration = async ({
  companyId,
  internationalTaxCode,
  domesticZeroRatedTaxCode,
  naTaxCode,
  mileageTaxCode,
  companyHasManualTax,
  reportedTaxAmountCode,
}: TaxConfig): Promise<TaxConfig> => {
  const result = await getClient().sendCommand({
    method: 'set_tax_configuration',
    params: {
      company_id: companyId,
      domestic_zero_rated_tax_code: domesticZeroRatedTaxCode || null,
      international_tax_code: internationalTaxCode || null,
      na_tax_code: naTaxCode || null,
      mileage_tax_code: mileageTaxCode || null,

      editable_tax_amount: companyHasManualTax,

      reported_tax_amount_code: reportedTaxAmountCode,
    },
  });
  return normalizeTaxConfig(result);
};

export const updateDefaultTaxesConfig = async (
  companyId: string,
  {
    internationalTaxCode,
    domesticZeroRatedTaxCode,
    naTaxCode,
    mileageTaxCode,
  }: DefaultTaxesConfig,
): Promise<TaxConfig> => {
  const result = await getClient().sendCommand({
    method: 'set_default_taxes_configuration',
    params: {
      company_id: companyId,
      domestic_zero_rated_tax_code: domesticZeroRatedTaxCode || null,
      international_tax_code: internationalTaxCode || null,
      na_tax_code: naTaxCode || null,
      mileage_tax_code: mileageTaxCode || null,
    },
  });
  return normalizeTaxConfig(result);
};

export const updateManualTaxConfig = async (
  companyId: UUID,
  { companyHasManualTax, reportedTaxAmountCode }: ManualTaxConfig,
): Promise<TaxConfig> => {
  const result = await getClient().sendCommand({
    method: 'set_manual_tax_configuration',
    params: {
      company_id: companyId,
      editable_tax_amount: companyHasManualTax,
      reported_tax_amount_code: reportedTaxAmountCode,
    },
  });
  return normalizeTaxConfig(result);
};

export const getTaxConfiguration = async (companyId: string) => {
  const result = await getClient().sendCommand({
    method: 'get_tax_configuration',
    params: { company_id: companyId },
  });
  return normalizeTaxConfig(result);
};
