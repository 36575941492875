import Checkbox from 'components/elements/Checkbox';

import { useSelection, ActionType } from '../selectionContext';

const SelectAllInput = () => {
  const selectionContext = useSelection();
  if (!selectionContext) return null;

  const handleChange = () => {
    selectionContext.dispatch({
      type: selectionContext.state.isAllSelected
        ? ActionType.UNSELECT_ALL
        : ActionType.SELECT_ALL,
    });
  };

  const {
    state: { count, isAllSelected, selectedIds, excludedIds },
    disabled,
  } = selectionContext;

  return (
    <Checkbox
      checked={isAllSelected}
      partiallyChecked={excludedIds.length > 0 || selectedIds.length > 0}
      onChange={handleChange}
      disabled={disabled || !count}
    />
  );
};

export default SelectAllInput;
