import { ExpenseCategoryGroup } from '@neo1/client/lib/entities/expenseCategory/types';
import {
  Country,
  Currency,
} from '@neo1/client/lib/entities/referentialData/types';
import { AppCoreState } from 'redux/types';

const selectReferentialCountries = ({
  app: {
    referential: { countries },
  },
}: AppCoreState): Record<string, Country> => countries || {};

const selectReferentialCurrencies = ({
  app: {
    referential: { currencies },
  },
}: AppCoreState): Record<string, Currency> => currencies || {};

const selectReferentialExpenseCategoryGroups = ({
  app: {
    referential: { expenseCategoryGroups },
  },
}: AppCoreState): Record<string, ExpenseCategoryGroup> =>
  expenseCategoryGroups || {};

const selectAppReferential = (state: AppCoreState) => ({
  countries: selectReferentialCountries(state),
  currencies: selectReferentialCurrencies(state),
  expenseCategoryGroups: selectReferentialExpenseCategoryGroups(state),
});

export default selectAppReferential;
