import { AppConfig } from '../types';

const env: AppConfig = {
  api: '/api',
  apiRpc: '/api/rpc',
  safeApiRpc: '/safe/api/rpc', // this is not suposed to be used in dev.
  apiFiles: '/api/files',
  assetsPath: '',
  imagesContextPath: '/images',
  localesContextPath: '/locales',
  pollVersionInterval: null,
  slackContextPath: '/slack',
  googleApiBrowserKey: 'AIzaSyB4DBv7NmqW-e_BR7QYgm7dBiuuLHJg1l4',
  helpSiteUrl: 'http://neoone.wpengine.com/',
  enableCaptcha: false,
  signupUrl: 'https://signup.integration.neo1.com',
  features: {
    purchase: {
      hotels: true,
    },
    profile: {
      slack: false,
    },
  },
  googleCaptcha: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  buildVersion: '1.0.0-dev',
  companyHouseKey: 'rM7xqGqhWR8g8_1i1fkV-2bpOAPZvhC2Tl2zVrAb',
  didUEnjoySurveyURL:
    'https://survey.diduenjoy.com/cdc38a8f-e04d-4e63-a2f0-06274c8d6096',
  // By default 'amplitudeAPIKey' is `undefined` to prevent sending events to amplitude from local machines.
  // However if you need to test amplitude in your local machine use this API key: '04380c8d7e71ed6709d1be66025f5dd6'
  // which correspond to the project 'Neo1 (dev)' which is dedicated to this purpose.
  amplitudeAPIKey: undefined,
  // By default 'churnZeroJsScriptUrl' and 'churnZeroAPIKey' are `undefined` to prevent sending events to ChurnZero
  // from local machines.
  // However if you need to test ChurnZero in your local machine use this js URL: 'https://amexgbt-dev.us1app.churnzero.net/churnzero.js'
  // and this API key: '1!q-d4uqgYx0yLWxshybmWhYRJGIng2nnuc9IEQLiMgWwt1550'
  // which correspond to the "dev" project in our ChurnZero account.
  churnZeroJsScriptUrl: undefined,
  churnZeroAPIKey: undefined,
  // By default 'gtmId' is `undefined` to prevent sending events to GTM from local machines.
  // However if you need to test it in you local machine use this container ID: 'GTM-PBN3HVL'
  gtmId: undefined,
  lolaDomainIds: [],
  lolaFreshworksWidgetId: '47000005698',
  selfRegistrationDomainIds: [],
  selfRegistrationFreshworksWidgetId: '47000005714',
  unsupportedBrowserBanner: true,
  enableTravelDeeplink: true,
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!important!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // For some reasons, QA need to test the neo travel interface to make sure the whole punchout travel workflow.
  // And punchout to a new browser tab blocks their automated tests.
  // So here we exceptionally use this configuration property to make the punchout url opens in the current tab.
  // Never set this configuration key as true in dev or prod, the only environment that this should be true is qa.neo1.com
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!important!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  openNeoTravelDeeplinkInTheSameBrowserTabForQAScreenerTestToBeTrashedAsSoonAsPossible:
    false,
  // ! important please keep this branding config commented for testing branding theme
  // branding: {
  //   primaryColor: '#ffc72c',
  //   logo: 'logos/partners/egenciaShort.svg',
  // },
  delayDisplayUpdateModal: 5 * 60 * 1000,
  oneTrustId: '4b0c71b1-d264-4fba-983c-458dbb132747-test',
};

export default env;
