import { wrapResults } from '@neo1/client/lib/rpc/batch';
import { getClient } from '@neo1/client/lib/rpc/client';
import { formatDateTimeToUTC } from '@neo1/core/utils/dates';
import { UUID } from '../common';
import { User } from '../user/types';
import {
  CreateDelegationPayload,
  Delegation,
  DelegationValues,
  DeleteDelegationPayload,
} from './types';
import { normalizeDelegation } from './utils';

export const createDelegation = async (
  { id }: User,
  delegation: DelegationValues,
): Promise<Delegation> => {
  const {
    recipientId,
    startDate,
    endDate,
    startTime,
    endTime,
    delegationType,
  } = delegation;

  const delegationPayload: CreateDelegationPayload = {
    recipient_id: recipientId,
    start_date: formatDateTimeToUTC(startDate, startTime),
    end_date: formatDateTimeToUTC(endDate, endTime),
    delegation_type: delegationType,
  };

  const results = await getClient().sendCommand({
    method: 'delegate_account',
    params: {
      user_id: id,
      ...delegationPayload,
    },
  });
  return normalizeDelegation(results);
};

export const deleteDelegation = async (id: Delegation['id']): Promise<void> => {
  const delegationPayload: DeleteDelegationPayload = {
    delegation_id: id,
  };

  await getClient().sendCommand({
    method: 'delete_delegation',
    params: delegationPayload,
  });
};

export const getDelegations = async (
  userId: UUID,
): Promise<{
  toMe: Delegation[];
  toOthers: Delegation[];
  past: Delegation[];
}> => {
  const results = await getClient().batchCommands(
    [
      {
        method: 'get_not_expired_delegations',
        params: {
          user_id: userId,
        },
        id: 1,
      },
      {
        method: 'get_active_delegations_by_recipient',
        params: {
          recipient_id: userId,
        },
        id: 2,
      },
      {
        method: 'get_user_expired_or_deleted_delegations',
        params: {
          user_id: userId,
        },
        id: 3,
      },
    ],
    false,
  );
  const batchResults = wrapResults(results);
  const toOthers = batchResults.get(1, []).map(normalizeDelegation);
  const toMe = batchResults.get(2, []).map(normalizeDelegation);
  const past = batchResults.get(3, []).map(normalizeDelegation);
  return { toMe, toOthers, past };
};

export const getDelegationsToMe = async (
  userId: UUID,
): Promise<Delegation[]> => {
  const results = await getClient().sendCommand({
    method: 'get_active_delegations_by_recipient',
    params: { recipient_id: userId },
  });
  return results.map(normalizeDelegation);
};
