import { reify } from '@neo1/client';
import { TAX_ENTITY_NAME, TaxType } from './constants';
import { Tax, TaxConfig } from './types';

export const normalizeTaxConfig = (rawData: any): TaxConfig => {
  const data = reify(rawData);
  return {
    companyId: data.getString('company_id'),
    domesticZeroRatedTaxCode: data.getString('domestic_zero_rated_tax_code'),
    internationalTaxCode: data.getString('international_tax_code'),
    naTaxCode: data.getString('na_tax_code'),
    mileageTaxCode: data.getString('mileage_tax_code'),
    companyHasManualTax: data.getBoolean('editable_tax_amount'),
    reportedTaxAmountCode: data.getString('reported_tax_amount_code'),
  };
};

export const normalizeTax = (rawData: any): Tax => {
  const data = reify(rawData);
  return {
    entityName: TAX_ENTITY_NAME,
    description: data.getString('description'),
    taxCode: data.getString('tax_code'),
    id: data.getString('tax_code'),
    taxRate: data.getNumber('tax_rate'),
    isSynchronized: data.getBoolean('is_synchronized'),
    alteredBy: data.getUuid('altered_by', null),
    taxType: data.getString('tax_type') as TaxType,
  };
};
