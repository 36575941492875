import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import classNames from 'classnames';
import {
  syncExtractService,
  disconnectExtractService,
} from '@neo1/client/lib/entities/company/api';
import { TaxType } from '@neo1/client/lib/entities/tax/constants';
import { Tax } from '@neo1/client/lib/entities/tax/types';
import {
  selectTaxConfig,
  selectTaxes,
} from 'modules/Settings/Taxes/redux/selectors';
import {
  load as loadCompanyTaxes,
  loadTaxConfig,
  setTaxConfig,
} from 'modules/Settings/Taxes/redux/thunks';
import { enumToLabel } from '@neo1/core/utils/strings';
import useAsyncState from 'hooks/asyncState';
import {
  notifySuccess,
  notifyError,
  notifyWarning,
} from 'modules/App/redux/notifications/toaster/thunks';
import { load as loadCompany } from 'redux/company/thunks';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import EditForm from 'components/composed/EditForm';
import FormField from 'components/elements/form/Field';
import SelectBox from 'components/elements/form/fields/SelectBox';
import Button, { ButtonTheme, ButtonSize } from 'components/elements/Button';
import Icon from 'components/elements/Icon';
import { getAccountingConfig } from 'redux/accountingIntegrations/thunks';
import { setIntegrationSettings } from 'redux/accountingIntegrations/actions';
import { CompanyExtractService } from '@neo1/client/lib/entities/company/constants';
import { RpcClientError, RpcErrorCode } from '@neo1/client';
import { mapTaxToOption } from 'components/composed/SelectTax';
import styles from './ConfigureAccounting.module.css';
import messages from './messages';

const INITIAL_VALUES: ConfigureAccountingValues = {
  internationalTax: '',
  zeroRatedTax: '',
  naTax: '',
  mileageZeroRatedTax: '',
};

type ConfigureAccountingValues = {
  internationalTax: string;
  zeroRatedTax: string;
  naTax: string;
  mileageZeroRatedTax: string;
};

type Props = {
  onClose(): void;
  accountingService: CompanyExtractService;
};

const mapZeroRatedTaxesToOption = (zeroRatedTaxes: any[], tax: Tax) => {
  if (tax.taxRate === 0) {
    return zeroRatedTaxes.concat(mapTaxToOption(tax));
  }

  return zeroRatedTaxes;
};

function ConfigureAccounting({ onClose, accountingService }: Props) {
  const intl = useIntl();
  const company = useSelector(selectActingCompany);
  const taxConfig = useSelector(selectTaxConfig);
  // Only imported taxes should be passed.
  // Because the form is meant to be used to pick synchronized taxes to configure the defaults for system taxes.
  const taxes = useSelector(selectTaxes).filter(
    (tax) => tax.taxType === TaxType.Imported,
  );

  const [isSynchAccounting, setIsSynchAccounting] = useState(false);
  const [isTaxLoading, setTaxLoading] = useState(false);
  const isFormVisible = isSynchAccounting && !isEmpty(taxes);
  const dispatch = useDispatch();

  const selectPlaceholder = intl.formatMessage(messages.selectPlaceholder);
  const internationalTaxOptions = taxes.map(mapTaxToOption);
  const zeroRatedTaxOptions = taxes.reduce(mapZeroRatedTaxesToOption, []);

  const onLoadTaxConfig = useCallback(async () => {
    await dispatch(loadTaxConfig);
  }, [dispatch]);

  const {
    execute: executeLoadTaxConf,
    error: loadingTaxConfError,
    isLoading: isLoadingTaxConfig,
  } = useAsyncState(onLoadTaxConfig);

  useEffect(() => {
    executeLoadTaxConf();
  }, [executeLoadTaxConf]);

  useEffect(() => {
    if (loadingTaxConfError) {
      dispatch(notifyError(loadingTaxConfError));
    }
  }, [dispatch, loadingTaxConfError]);
  const onDisconnectService = () => {
    const connectedServiceName = enumToLabel(accountingService);

    dispatch(
      notifyWarning(intl.formatMessage(messages.notifyWarningMessage), {
        label: intl.formatMessage(messages.modalCancelTitle),
        onClick: () => {
          onClose();
          disconnectExtractService(company.id)
            .then(() => dispatch(loadCompany(company.id)))
            .then(() => dispatch(getAccountingConfig(company.id)))
            .then(() =>
              dispatch(
                notifySuccess(
                  intl.formatMessage(messages.notifySuccessCancelMessage, {
                    connectedServiceName,
                  }),
                ),
              ),
            )
            .catch((error) => dispatch(notifyError(error)));
        },
      }),
    );
  };
  const onSubmit = useCallback(
    async (values: ConfigureAccountingValues) => {
      const { companyHasManualTax, reportedTaxAmountCode } = taxConfig;
      await dispatch(
        setTaxConfig({
          companyId: company.id,
          internationalTaxCode: values.internationalTax,
          companyHasManualTax,
          naTaxCode: values.naTax,
          domesticZeroRatedTaxCode: values.zeroRatedTax,
          mileageTaxCode: values.mileageZeroRatedTax,
          reportedTaxAmountCode,
        }),
      );
      dispatch(
        notifySuccess(intl.formatMessage(messages.notifySuccessSubmitMessage)),
      );
      dispatch(setIntegrationSettings(accountingService, true));
      onClose();
    },
    [company.id, dispatch, taxConfig, intl, accountingService, onClose],
  );

  const renderFooter = (_, isSubmitting: boolean) => {
    if (!isSynchAccounting) return null;

    if (isFormVisible) {
      return [
        <Button
          label={intl.formatMessage(messages.modalCloseTitle)}
          onClick={onClose}
          theme={ButtonTheme.Ghost}
          size={ButtonSize.Large}
          disabled={isSubmitting}
        />,
        <Button
          type="submit"
          label={intl.formatMessage(messages.modalSubmitTitle)}
          size={ButtonSize.Large}
          loading={isSubmitting}
        />,
      ];
    }

    if (isEmpty(taxes)) {
      return (
        <Button
          label={intl.formatMessage(messages.modalCloseTitle)}
          onClick={onClose}
          size={ButtonSize.Large}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    setTaxLoading(true);
    syncExtractService(company.id)
      .then(async () => {
        await dispatch(loadCompanyTaxes);
        setTimeout(() => setTaxLoading(false), 3000);
        setIsSynchAccounting(true);
      })
      .catch((err: RpcClientError) => {
        if (err.code !== RpcErrorCode.ExternalServiceFailure) {
          dispatch(notifyError(err));
        }
      });
  }, []);

  return (
    <EditForm
      isLoading={isLoadingTaxConfig}
      initialValues={INITIAL_VALUES}
      className={classNames(styles.formContainer, {
        [styles.formContainerLoading]: !isSynchAccounting,
      })}
      onClose={onDisconnectService}
      title={intl.formatMessage(messages.modalTitle, {
        service: capitalize(accountingService),
      })}
      onSubmit={onSubmit}
      renderFooter={renderFooter}
      isOpen
    >
      {isFormVisible ? (
        <>
          <h3 className={styles.formTitle}>
            {intl.formatMessage(messages.formTitle, {
              service: accountingService,
            })}
          </h3>
          <p className={styles.formDescription}>
            {intl.formatMessage(messages.formDescription1)}
          </p>
          <FormField
            title={intl.formatMessage(messages.internationalTax)}
            name="internationalTax"
            info={intl.formatMessage(messages.internationalTaxInfo)}
          >
            <SelectBox
              placeholder={selectPlaceholder}
              options={internationalTaxOptions}
            />
          </FormField>
          <FormField
            title={intl.formatMessage(messages.zeroRatedTax)}
            name="zeroRatedTax"
            info={intl.formatMessage(messages.zeroRatedTaxInfo)}
          >
            <SelectBox
              placeholder={selectPlaceholder}
              options={zeroRatedTaxOptions}
            />
          </FormField>
          <FormField
            title={intl.formatMessage(messages.naTax)}
            name="naTax"
            info={intl.formatMessage(messages.naTaxInfo)}
          >
            <SelectBox
              placeholder={selectPlaceholder}
              options={zeroRatedTaxOptions}
            />
          </FormField>
          <FormField
            title={intl.formatMessage(messages.mileageZeroRatedTax)}
            name="mileageZeroRatedTax"
            info={intl.formatMessage(messages.mileageZeroRatedTaxInfo)}
          >
            <SelectBox
              placeholder={selectPlaceholder}
              options={zeroRatedTaxOptions}
            />
          </FormField>
        </>
      ) : (
        <>
          <h3 className={styles.formTitle}>
            {intl.formatMessage(messages.loadingConnectionTitle)}
          </h3>
          <p className={styles.formDescription}>
            {intl.formatMessage(messages.loadingConnectionDescription)}
          </p>
          <div className={styles.formLoading}>
            {isTaxLoading ? (
              <Icon
                className={classNames(
                  styles.formLoadingIcon,
                  styles.loadingIcon,
                )}
                name="sync"
              />
            ) : (
              <Icon
                className={classNames(
                  styles.formLoadingIcon,
                  styles.successIcon,
                )}
                name="check"
              />
            )}
          </div>
        </>
      )}
    </EditForm>
  );
}

export default ConfigureAccounting;
