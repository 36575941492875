import { UUID_REGEX_AS_STRING } from '@neo1/client/lib/entities/index';

export const ROUTE_NAME_SETTINGS = 'settings';

export const ROUTE_PATH_SETTINGS = '/settings';

// General settings
export const ROUTE_NAME_SETTINGS_GENERAL = `settingsGeneral`;
export const ROUTE_PATH_SETTINGS_GENERAL = `${ROUTE_PATH_SETTINGS}/general`;
// Business info
export const ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO = 'settingsBusinessInfo';
export const ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO = `${ROUTE_PATH_SETTINGS_GENERAL}/business_info`;
// PEOPLE
export const ROUTE_NAME_SETTINGS_GENERAL_PEOPLE = 'settingsPeople';
export const ROUTE_PATH_SETTINGS_GENERAL_PEOPLE = `${ROUTE_PATH_SETTINGS_GENERAL}/people`;
export const ROUTE_PATH_SETTINGS_GENERAL_PEOPLE_USERS = `${ROUTE_PATH_SETTINGS_GENERAL}/people/users`;
export const ROUTE_PATH_SETTINGS_GENERAL_PEOPLE_GROUPS = `${ROUTE_PATH_SETTINGS_GENERAL_PEOPLE}/groups`;
export const ROUTE_PATH_SETTINGS_GENERAL_PEOPLE_RECIPIENTS = `${ROUTE_PATH_SETTINGS_GENERAL_PEOPLE}/recipients`;
// Payment methods
export const ROUTE_NAME_SETTINGS_GENERAL_PAYMENT_METHODS =
  'settingsGeneralPaymentMethods';
export const ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS = `${ROUTE_PATH_SETTINGS_GENERAL}/payment_methods`;
export const ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_CARD_INSTRUMENTS = `${ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS}/card/:accountId(${UUID_REGEX_AS_STRING})/instruments`;
export const ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_AMEX_VIRTUAL_CARD_INSTRUMENTS = `${ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS}/amex_virtual_card/:accountId(${UUID_REGEX_AS_STRING})/instruments/:type`;
export const ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_AMEX_VIRTUAL_CARD_SUMMARY = `${ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS}/amex_virtual_card/:accountId(${UUID_REGEX_AS_STRING})/summary`;

// Expense settings
export const ROUTE_NAME_SETTINGS_EXPENSE = 'settingsExpense';
export const ROUTE_PATH_SETTINGS_EXPENSE = `${ROUTE_PATH_SETTINGS}/expense`;
// Accounting
export const ROUTE_NAME_SETTINGS_EXPENSE_FINANCE = 'settingsExpenseFinance';
export const ROUTE_PATH_SETTINGS_EXPENSE_FINANCE = `${ROUTE_PATH_SETTINGS_EXPENSE}/finance`;
// Taxes
export const ROUTE_NAME_SETTINGS_EXPENSE_TAXES = 'settingsExpenseTaxes';
export const ROUTE_PATH_SETTINGS_EXPENSE_TAXES = `${ROUTE_PATH_SETTINGS_EXPENSE}/taxes`;
// Expense categories
export const ROUTE_NAME_SETTINGS_EXPENSE_CATEGORIES =
  'settingsExpenseCategories';
export const ROUTE_PATH_SETTINGS_EXPENSE_CATEGORIES = `${ROUTE_PATH_SETTINGS_EXPENSE}/categories`;
// Spend policies
export const ROUTE_NAME_SETTINGS_EXPENSE_RULES = 'settingsExpenseRules';
export const ROUTE_PATH_SETTINGS_EXPENSE_RULES = `${ROUTE_PATH_SETTINGS_EXPENSE}/rules`;
// Mileage
export const ROUTE_NAME_SETTINGS_EXPENSE_MILEAGE = 'settingsExpenseMileage';
export const ROUTE_PATH_SETTINGS_EXPENSE_MILEAGE = `${ROUTE_PATH_SETTINGS_EXPENSE}/mileage`;

export const SETTINGS_GENERAL_BUSINESS_INFO_PENDING_BANNER_ADMIN = {
  description:
    'Amazon Punchout setup and banking integrations will be enabled as soon as your business is fully active.',
};
