import {
  SetExpenseCategories,
  Actions,
  AddExpenseCategory,
  DelExpenseCategory,
} from './actions';
import { ExpenseCategoriesState } from './types';

const initialState: ExpenseCategoriesState = {
  ids: [],
};

const expenseCategoriesReducer = (
  state: ExpenseCategoriesState = initialState,
  action: SetExpenseCategories | AddExpenseCategory | DelExpenseCategory = null,
): ExpenseCategoriesState => {
  switch (action?.type) {
    case Actions.SET_EXPENSE_CATEGORIES:
      return {
        ...state,
        ids: action.ids,
      };

    case Actions.ADD_EXPENSE_CATEGORY:
      return {
        ...state,
        ids: [...state.ids, action.id],
      };

    case Actions.DEL_EXPENSE_CATEGORY:
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.id),
      };

    default:
      return state;
  }
};

export default expenseCategoriesReducer;
