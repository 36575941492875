import Checkbox from 'components/elements/Checkbox';

import { useSelection, ActionType } from '../selectionContext';

type Props = {
  id: string;
  className?: string;
  onChange?(isSelected: boolean): void;
  disabled: boolean;
  testId?: string;
};

const defaultProps: Partial<Props> = {
  className: '',
  onChange: undefined,
  testId: undefined,
};

const SelectInput = ({ id, className, onChange, disabled, testId }: Props) => {
  const selectionContext = useSelection();
  if (!selectionContext) return null;

  const handleChange = (checked: boolean) => {
    const type = checked ? ActionType.SELECT : ActionType.UNSELECT;
    selectionContext.dispatch({ type, id });

    if (onChange) {
      onChange(checked);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const {
    state: { isAllSelected, selectedIds, excludedIds },
    disabled: selectionDisabled,
  } = selectionContext;
  const isChecked =
    !disabled &&
    (selectedIds.includes(id) || isAllSelected) &&
    (!excludedIds.includes(id) || !isAllSelected);

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleChange}
      onClick={handleClick}
      className={className}
      disabled={disabled || selectionDisabled}
      testId={testId}
    />
  );
};

SelectInput.defaultProps = defaultProps;

export default SelectInput;
