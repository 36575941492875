import { AppConfig } from '../types';

declare let NEO1_API_MAJOR_VERSION: string;
declare let NEO1_UI_URL_PREFIX: string;
declare let NEO1_UI_TECHNICAL_VERSION: string;

const env: AppConfig = {
  api: `/api/${NEO1_API_MAJOR_VERSION}`,
  apiRpc: `/api/${NEO1_API_MAJOR_VERSION}/rpc`,
  safeApiRpc: `/safe/api/${NEO1_API_MAJOR_VERSION}/rpc`,
  apiFiles: `/api/${NEO1_API_MAJOR_VERSION}/files`,
  localesContextPath: `${NEO1_UI_URL_PREFIX}/locales`,
  imagesContextPath: `${NEO1_UI_URL_PREFIX}/images`,
  assetsPath: `${NEO1_UI_URL_PREFIX}`,
  enableCaptcha: true,
  slackContextPath: '/slack',
  googleApiBrowserKey: 'AIzaSyB4DBv7NmqW-e_BR7QYgm7dBiuuLHJg1l4',
  helpSiteUrl: 'http://neoone.wpengine.com/',
  signupUrl: 'https://signup.demo.neo1.com',
  buildVersion: `${NEO1_UI_TECHNICAL_VERSION}`,
  pollVersionInterval: 20 * 60 * 1000, // Polling interval for latest ui version (in ms)
  features: {
    purchase: {
      hotels: true,
    },
    profile: {
      slack: true,
    },
  },
  googleCaptcha: '6Lc7EnUgAAAAADEXZZW9ixEimRLvArrLfzdzLkht',
  companyHouseKey: 'rM7xqGqhWR8g8_1i1fkV-2bpOAPZvhC2Tl2zVrAb',
  didUEnjoySurveyURL:
    'https://survey.diduenjoy.com/cdc38a8f-e04d-4e63-a2f0-06274c8d6096',
  amplitudeAPIKey: undefined,
  churnZeroJsScriptUrl: undefined,
  churnZeroAPIKey: undefined,
  gtmId: undefined,
  lolaDomainIds: [],
  lolaFreshworksWidgetId: '47000005698',
  selfRegistrationDomainIds: [],
  selfRegistrationFreshworksWidgetId: '47000005714',
  unsupportedBrowserBanner: true,
  cloudwatchRum: {
    enabled: true,
    appMonitorId: '59dbbb6c-dec0-4798-a0dd-422e82440a3e',
    appMonitorVersion: '1.0.0',
    appMonitorRegion: 'us-east-1',
    scriptUrl: 'https://client.rum.us-east-1.amazonaws.com/1.2.1/cwr.js',
    config: {
      sessionSampleRate: 1,
      guestRoleArn:
        'arn:aws:iam::507264273136:role/Prod-Application-RumUnauthRole-SEC8WDVRQKKG',
      identityPoolId: 'us-east-1:b37a7b57-6412-467f-8854-2be977e33875',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: true,
    },
  },
  enableTravelDeeplink: true,
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!important!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // For some reasons, QA need to test the neo travel interface to make sure the whole punchout travel workflow.
  // And punchout to a new browser tab blocks their automated tests.
  // So here we exceptionally use this configuration property to make the punchout url opens in the current tab.
  // Never set this configuration key as true in dev or prod, the only environment that this should be true is qa.neo1.com
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!important!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  openNeoTravelDeeplinkInTheSameBrowserTabForQAScreenerTestToBeTrashedAsSoonAsPossible:
    false,
  delayDisplayUpdateModal: 5 * 60 * 1000,
  oneTrustId: '4b0c71b1-d264-4fba-983c-458dbb132747',
};

export default env;
