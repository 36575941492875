import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { TaxType } from '@neo1/client/lib/entities/tax/constants';
import { Tax } from '@neo1/client/lib/entities/tax/types';
import FormField from 'components/elements/form/Field';
import SelectBox from 'components/elements/form/fields/SelectBox';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import {
  selectTaxConfig,
  selectTaxes,
} from 'modules/Settings/Taxes/redux/selectors';
import { mapTaxToOption } from 'components/composed/SelectTax';

import {
  loadTaxConfig,
  load as loadCompanyTaxes,
} from 'modules/Settings/Taxes/redux/thunks';
import useAsyncState from 'hooks/asyncState';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';

import messages from './messages';
import styles from './Taxconfiguration.module.css';

const mapZeroRatedTaxesToOption = (zeroRatedTaxes: any[], tax: Tax) => {
  if (tax.taxRate === 0) {
    return zeroRatedTaxes.concat(mapTaxToOption(tax));
  }

  return zeroRatedTaxes;
};

export const INITIAL_VALUES: ConfigureAccountingValues = {
  internationalTax: '',
  zeroRatedTax: '',
  naTax: '',
  mileageZeroRatedTax: '',
  companyHasManualTax: false,
  reportedTaxAmountCode: '',
};

export type ConfigureAccountingValues = {
  internationalTax: string;
  zeroRatedTax: string;
  naTax: string;
  mileageZeroRatedTax: string;
  companyHasManualTax: boolean;
  reportedTaxAmountCode: string;
};

type Props = { onSkip: () => void };

const TaxConfigurationContent = ({ onSkip }: Props) => {
  const dispatch = useDispatch();
  const taxConfig = useSelector(selectTaxConfig);
  const { resetForm } = useFormikContext<ConfigureAccountingValues>();
  const onLoad = useCallback(async () => {
    await dispatch(loadTaxConfig);
    await dispatch(loadCompanyTaxes);
  }, [dispatch]);

  const { execute, error, isLoading } = useAsyncState(onLoad);

  useEffect(() => {
    execute();
  }, [execute]);

  useEffect(() => {
    if (error) {
      dispatch(notifyError(error));
    }
  }, [dispatch, error]);

  useEffect(() => {
    resetForm({
      values: {
        internationalTax: taxConfig.internationalTaxCode || '',
        zeroRatedTax: taxConfig.domesticZeroRatedTaxCode || '',
        naTax: taxConfig.naTaxCode || '',
        mileageZeroRatedTax: taxConfig.mileageTaxCode || '',
        companyHasManualTax: taxConfig.companyHasManualTax || false,
        reportedTaxAmountCode: taxConfig.reportedTaxAmountCode || '',
      },
    });
  }, [resetForm, taxConfig]);

  const intl = useIntl();

  const taxes = useSelector(selectTaxes).filter(
    ({ taxType }) => taxType === TaxType.Imported,
  );
  const selectPlaceholder = intl.formatMessage(messages.selectPlaceholder);
  const internationalTaxOptions = taxes.map(mapTaxToOption);
  const zeroRatedTaxOptions = taxes.reduce(mapZeroRatedTaxesToOption, []);

  const taxForm = (
    <>
      <h3 className={styles.formTitle}>
        {intl.formatMessage(messages.formTitle)}
      </h3>
      <div className={styles.formDescription}>
        {intl.formatMessage(messages.formDescription1)}
      </div>
      <FormField
        title={intl.formatMessage(messages.internationalTax)}
        name="internationalTax"
        info={intl.formatMessage(messages.internationalTaxInfo)}
      >
        <SelectBox
          placeholder={selectPlaceholder}
          options={internationalTaxOptions}
        />
      </FormField>
      <FormField
        title={intl.formatMessage(messages.zeroRatedTax)}
        name="zeroRatedTax"
        info={intl.formatMessage(messages.zeroRatedTaxInfo)}
      >
        <SelectBox
          placeholder={selectPlaceholder}
          options={zeroRatedTaxOptions}
        />
      </FormField>
      <FormField
        title={intl.formatMessage(messages.naTax)}
        name="naTax"
        info={intl.formatMessage(messages.naTaxInfo)}
      >
        <SelectBox
          placeholder={selectPlaceholder}
          options={zeroRatedTaxOptions}
        />
      </FormField>
      <FormField
        title={intl.formatMessage(messages.mileageZeroRatedTax)}
        name="mileageZeroRatedTax"
        info={intl.formatMessage(messages.mileageZeroRatedTaxInfo)}
      >
        <SelectBox
          placeholder={selectPlaceholder}
          options={zeroRatedTaxOptions}
        />
      </FormField>
    </>
  );
  const noConfig = (
    <div className={styles.noTaxesMsg}>
      <div>{intl.formatMessage(messages.NoTaxes)}</div>
    </div>
  );

  return (
    <StepContent
      submitTitle={isEmpty(taxes) ? undefined : 'Continue'}
      onSkip={isEmpty(taxes) ? onSkip : undefined}
      isLoading={isLoading}
      loadingMsg={intl.formatMessage(messages.loadingConnectionDescription)}
    >
      {isEmpty(taxes) ? noConfig : taxForm}
    </StepContent>
  );
};

export default TaxConfigurationContent;
