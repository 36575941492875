import uniq from 'lodash/uniq';
import { SortTypes } from '@neo1/core/utils/collections';
import { ActionTypeName as usersActions, Actions } from './actions';
import { State } from './types';

const initialState: State = {
  companyUserIds: [],
  defaultCodingByUserId: {},
  addressesByUserId: {},
  docsByUserId: {},
  vehiclesByUserId: {},
  filters: {
    search: '',
    sort: { prop: 'displayName', dir: SortTypes.ASC },
  },
};

export default function reducer(state = initialState, action: Actions = null) {
  switch (action?.type) {
    case usersActions.SET_COMPANY_USER_IDS:
      return {
        ...state,
        companyUserIds: action.companyUserIds,
      };
    case usersActions.SET_COMPANY_USER:
      return {
        ...state,
        companyUserIds: uniq([...state.companyUserIds, action.companyUserId]),
      };

    case usersActions.DELETE_COMPANY_USER:
      return {
        ...state,
        companyUserIds: state.companyUserIds.filter(
          (id) => id !== action.companyUserId,
        ),
      };

    case usersActions.SET_USER_DEFAULT_CODINGS:
      return {
        ...state,
        defaultCodingByUserId: action.defaultCodingByUserId,
      };

    case usersActions.SET_USER_DEFAULT_CODING:
      return {
        ...state,
        defaultCodingByUserId: {
          ...state.defaultCodingByUserId,
          [action.payload.userId]: action.payload.defaultCoding,
        },
      };

    case usersActions.UPDATE_USER_DEFAULT_CODING:
      return {
        ...state,
        defaultCodingByUserId: {
          ...(state.defaultCodingByUserId || {}),
          [action.payload.userId]: action.payload.defaultCoding,
        },
      };

    case usersActions.SET_USER_ADDRESSES:
      return {
        ...state,
        addressesByUserId: {
          ...(state.addressesByUserId || {}),
          [action.payload.userId]: action.payload.userAddresses,
        },
      };

    case usersActions.SET_USER_ADDRESS:
      return {
        ...state,
        addressesByUserId: {
          ...(state.addressesByUserId || {}),
          [action.payload.userId]: {
            ...state.addressesByUserId[action.payload.userId],
            ids: uniq([
              ...(state.addressesByUserId[action.payload.userId]?.ids || []),
              action.payload.addressId,
            ]),
          },
        },
      };

    case usersActions.SET_USER_HOME_ADDRESS:
      return {
        ...state,
        addressesByUserId: {
          ...(state.addressesByUserId || {}),
          [action.payload.userId]: {
            ...state.addressesByUserId[action.payload.userId],
            home: action.payload.home,
          },
        },
      };

    case usersActions.DELETE_USER_ADDRESS:
      return {
        ...state,
        addressesByUserId: {
          ...(state.addressesByUserId || {}),
          [action.payload.userId]: {
            ...state.addressesByUserId[action.payload.userId],
            ids: (
              state.addressesByUserId[action.payload.userId]?.ids || []
            ).filter((id) => id !== action.payload.addressId),
          },
        },
      };

    case usersActions.SET_USER_DOCUMENTS:
      return {
        ...state,
        docsByUserId: {
          ...(state.docsByUserId || {}),
          [action.payload.userId]: action.payload.docIds,
        },
      };

    case usersActions.SET_USER_DOCUMENT:
      return {
        ...state,
        docsByUserId: {
          ...(state.docsByUserId || {}),
          [action.payload.userId]: uniq([
            ...(state.docsByUserId[action.payload.userId] || []),
            action.payload.docId,
          ]),
        },
      };

    case usersActions.DELETE_USER_DOCUMENT:
      return {
        ...state,
        docsByUserId: {
          ...(state.docsByUserId || {}),
          [action.payload.userId]: (
            state.docsByUserId[action.payload.userId] || []
          ).filter((id) => id !== action.payload.docId),
        },
      };

    case usersActions.SET_USER_VEHICLES:
      return {
        ...state,
        vehiclesByUserId: {
          ...(state.vehiclesByUserId || {}),
          [action.payload.userId]: action.payload.vehicleIds,
        },
      };

    case usersActions.SET_USER_VEHICLE:
      return {
        ...state,
        vehiclesByUserId: {
          ...(state.vehiclesByUserId || {}),
          [action.payload.userId]: uniq([
            ...(state.vehiclesByUserId[action.payload.userId] || []),
            action.payload.vehicleId,
          ]),
        },
      };

    case usersActions.DELETE_USER_VEHICLE:
      return {
        ...state,
        vehiclesByUserId: {
          ...(state.vehiclesByUserId || {}),
          [action.payload.userId]: (
            state.vehiclesByUserId[action.payload.userId] || []
          ).filter((id) => id !== action.payload.vehicleId),
        },
      };

    case usersActions.SET_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.search,
        },
      };

    case usersActions.SET_SORT_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: {
            ...state.filters.sort,
            ...action.options,
          },
        },
      };

    default:
      return state;
  }
}
