import { DelegationType } from '@neo1/client/lib/entities/delegation/constants';
import { ROUTE_PATH_PROFILE } from '../constants';

export const ROUTE_PATH_DELEGATIONS = `${ROUTE_PATH_PROFILE}/delegations`;

export const ROUTE_NAME_DELEGATIONS = 'profileDelegations';

export const ROUTE_PATH_MY_DELEGATIONS = `${ROUTE_PATH_DELEGATIONS}`;

export const ROUTE_PATH_DELEGATED_ACCOUNTS = `${ROUTE_PATH_DELEGATIONS}/delegated_accounts`;

export const ROUTE_PATH_PAST_DELEGATIONS = `${ROUTE_PATH_DELEGATIONS}/past`;

export const DELEGATION_TYPE_OPTIONS = [
  { label: 'Account access', value: DelegationType.AccountAccess },
  {
    label: 'Virtual Card funding approval',
    value: DelegationType.AmexVirtualFundingApproval,
  },
];

export enum DelegationView {
  Given = 'given',
  Received = 'received',
  Past = 'past',
}
