import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  selectActingUserSupervisees,
  selectCurrentCompany,
  selectCurrentUser,
  selectEffectiveUser,
} from 'modules/Authentification/redux/selectors';
import { ROOT_UUID } from '@neo1/client';
import { CompanyUser } from '@neo1/client/lib/entities/user/types';
import { FAKE_USER_ID } from 'redux/fakeState';
import { getEventNameByLocation } from 'utils/instrumentation';
import { isCompanyUser } from '@neo1/client/lib/entities/user/utils';
import {
  setUserId,
  logEvent,
  regenerateDeviceId,
  eventBasedOnLocation,
  removesEmail,
} from './amplitude';
import { startChurnZero, stopChurnZero, trackEvent } from './churnzero';

export type AppInstrumentationContext = {
  logEvent: typeof logEvent;
  logEventAnonymously: typeof logEvent;
  setUserId: typeof setUserId;
  regenerateDeviceId: typeof regenerateDeviceId;
};

const initialValue: AppInstrumentationContext = {
  logEvent: null,
  logEventAnonymously: null,
  setUserId,
  regenerateDeviceId,
};

export const InstrumentationContext =
  React.createContext<AppInstrumentationContext>(initialValue);

export type InjectedInstrumentationContextProps = {
  instrumentationContext: AppInstrumentationContext;
};

type Props = {
  children: React.ReactNode;
};
export const InstrumentationProvider = ({ children }: Props) => {
  const currentUser = useSelector(selectCurrentUser);
  const effectiveUser = useSelector(selectEffectiveUser);
  const history = useHistory();
  const user = currentUser as CompanyUser;
  const company = useSelector(selectCurrentCompany);
  const supervisees = useSelector(selectActingUserSupervisees);
  const isSupervisor = supervisees.length > 0;

  const logEventWithAdditionalUserData: AppInstrumentationContext['logEvent'] =
    ({ event, data: originalData, eventCallback }) => {
      let data = { ...originalData };
      if (user) {
        data.userId = user.id === ROOT_UUID ? 'root' : user.id;
        data.rolesList = user.rolesList;
        data.userEmail = user.email;
      }
      if (effectiveUser) {
        data.effectiveUserId = effectiveUser.id;
      }
      if (company) {
        // todo check with PO which companyName is actually wanted, effectiveCompany or currenctCompany.
        data.companyName = company.name;
      }

      data.isSupervisor = isSupervisor;

      // Remove all email addresses from data if the user didn't accept latest EULA
      if (user?.id !== ROOT_UUID && !user?.latestAcceptedEula?.isLatest) {
        data = removesEmail(data);
      }
      return logEvent({ event, data, eventCallback });
    };
  const contextValue = {
    ...initialValue,
    logEvent: logEventWithAdditionalUserData,
    logEventAnonymously: logEvent,
  };

  useEffect(() => {
    const unregister = history.listen(({ pathname, search }) => {
      const locationData = { pathname, search };
      const eventName = getEventNameByLocation(locationData);
      if (eventName) {
        logEventWithAdditionalUserData(
          eventBasedOnLocation(locationData, eventName),
        );
      }
    });
    return () => {
      unregister();
    };
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.id !== FAKE_USER_ID) {
      setUserId(currentUser.id);
      if (isCompanyUser(currentUser)) {
        startChurnZero(currentUser.companyId, currentUser.id);
        trackEvent(
          'Login',
          `${currentUser.firstName} ${currentUser.lastName} logged in`,
        );
      }
    } else {
      setUserId(null);
      stopChurnZero();
    }
  }, [currentUser, currentUser?.id, effectiveUser?.id]);
  return (
    <InstrumentationContext.Provider value={contextValue}>
      {children}
    </InstrumentationContext.Provider>
  );
};

/**
 * HOC to provide Instrumentation context to class components
 * @param {React.Component} WrappedComponent
 */
export function withInstrumentationContext<
  T extends InjectedInstrumentationContextProps,
  W extends Pick<
    T,
    Exclude<keyof T, keyof InjectedInstrumentationContextProps>
  >,
>(WrappedComponent: React.ComponentType<T>): React.FunctionComponent<W> {
  return (props: any) => (
    <InstrumentationContext.Consumer>
      {(context) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WrappedComponent {...props} instrumentationContext={context} />
      )}
    </InstrumentationContext.Consumer>
  );
}
/**
 * Hook for intrumentation context context
 */

export const useAmplitude = () => React.useContext(InstrumentationContext);

export enum Events {
  AmazonRegisterCredentials = 'amz_register_credentials',
  // Activation wizard events
  CloseGetStarted = 'close_activation_request_start',
  CloseAbout = 'close_ar_company_info',
  CloseAddress = 'close_ar_company_address',
  CloseOwners = 'close_ar_company_owners',
  CloseOwnersEditing = 'close_ar_editowner',
  CloseSummary = 'close_ar_send_request',
  CloseFinish = 'close_ar_final_step',
  SubmitGetStarted = 'start_activation_request',
  SubmitAbout = 'goto_company_address',
  SubmitAddress = 'goto_company_owners',
  SubmitOwners = 'goto_send_request',
  SubmitSummary = 'send_request',
  BackBtnAbout = 'backto_company_get_started',
  BackBtnAddress = 'backto_company_info',
  BackBtnOwners = 'backto_company_address',
  BackBtnSummary = 'backto_company_owners',
  CompanyInfoViewed = 'company_info_viewed',
  HasFilledCompanyType = 'has_filled_company_type',
  HasFilledEmployeeCount = 'has_filled_employee_count',
  SurveyViewed = 'survey_viewed',
  ViewedCompanyActivationRequest = 'company_activation_request_viewed',
  HoverWhyInfoRequired = 'hover_why_info_required',
  CompanyOwnersViewed = 'company_owners_viewed',
  CompanyHasOwnersSwitchClicked = 'company_has_owners_switch_clicked',
  // Activation wizard events end
  // Signup Events
  BackToStart = 'backto_start',
  GoToCreateAdminAccount = 'goto_create_admin_account',
  GoToAdminExists = 'goto_admin_exists',
  GoToAdminPending = 'goto_admin_pending',
  CheckPrivacyPolicy = 'check_privacy_policy',
  CheckTermsAndConditions = 'check_terms_conditions',
  ClickPrivacyPolicy = 'click_privacy_policy',
  ClickTermsAndConditions = 'click_terms_conditions',
  HasFilledFirstField = 'has_filled_name',
  HasFilledCompanyName = 'has_filled_company_name',
  HasFilledCompanyURL = 'has_filled_url',
  HasFilledFirstName = 'has_filled_first_name',
  HasFilledLastName = 'has_filled_last_name',
  HasFilledMobileNumber = 'has_filled_mobile_number',
  HasFilledRole = 'has_filled_role',
  HasFilledReferralCode = 'has_filled_referral_code',
  RegistrationCompleted = 'registration_completed',
  StartRegistration = 'start_registration',
  EmailAlreadyExist = 'email_already_exist',
  PendingActivationInSignUp = 'user_in_pending_activation_sign_up',
  TokenExpired = 'token_expired',
  PendingActivationInLogin = 'user_in_pending_activation_login',
  // Signup Events end
  HasConfirmedEmail = 'has_confirmed_email',
  SetPassword = 'set_password',
  PurchaseApprove = 'purchase_approve',
  DashboardTimelineBudget = 'dashboard_timeline_budget',
  DashboardTimelinePurchase = 'dashboard_timeline_purchase',
  DashboardScopeChanged = 'dashboard_display',
  RequestBudget = 'request_budget',
  SubmitBudget = 'submit_budget',
  SuppliesBuy = 'supplies_buy',
  PurchaseSubmit = 'purchase_submit',
  PurchaseAmazonSetup = 'purchase_amz_setup',
  SettingsAmazonSetup = 'settings_amz_setup',
  SubmitTx = 'submit_tx',
  DisplayTx = 'display_tx',
  TravelBook = 'travel_book',
  UserLogin = 'user_login',
  AuthorizePurchase = 'authorize_purchase',
  RejectPurchase = 'reject_purchase',
  AuthorizeBudget = 'authorize_budget',
  RejectBudget = 'reject_budget',
  AuthorizeBudgetChanges = 'authorize_budget_changes',
  RejectBudgetChanges = 'reject_budget_changes',
  AuthorizeSpend = 'authorize_spend',
  RejectAuthorizeSpend = 'reject_authorize_spend',
  ReviewSpend = 'review_spend',
  RejectReviewSpend = 'reject_review_spend',
  ApplyNowAmexCard = 'apply_now_amex_card',
  GetAccruals = 'get_accruals',
  RunExtract = 'run_extract',
  ReportsScope = 'reports_scope',
  ReportBuilderConfirm = 'report_builder_confirm',
  // Expense category settings
  CreateExpenseCategoryBtn = 'create_expense_category_btn',
  AddAutomationRuleBtn = 'add_automation_rule_btn',
  RenameExpenseCategory = 'rename_expense_category',
}
