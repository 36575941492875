export enum Actions {
  SET_AUTOMATION_RULES = 'one/settings/ExpenseCategories/SET_AUTOMATION_RULES',
  SET_AUTOMATION_RULE = 'one/settings/ExpenseCategories/SET_AUTOMATION_RULE',
  DELETE_AUTOMATION_RULE = 'one/settings/ExpenseCategories/DELETE_AUTOMATION_RULE',
}

type SetAutomationRules = {
  type: Actions.SET_AUTOMATION_RULES;
  ids: string[];
};

type SetAutomationRule = {
  type: Actions.SET_AUTOMATION_RULE;
  id: string;
};

type DeleteAutomationRule = {
  type: Actions.DELETE_AUTOMATION_RULE;
  id: string;
};

export function setAll(ids: string[]): SetAutomationRules {
  return { type: Actions.SET_AUTOMATION_RULES, ids };
}

export function set(id: string): SetAutomationRule {
  return { type: Actions.SET_AUTOMATION_RULE, id };
}

export function deleteAutomationRule(id: string): DeleteAutomationRule {
  return { type: Actions.DELETE_AUTOMATION_RULE, id };
}

export type AutomationRuleActions =
  | DeleteAutomationRule
  | SetAutomationRules
  | SetAutomationRule;
