// https://support.churnzero.net/hc/en-us/articles/360004683552-Integrate-ChurnZero-using-Javascript
class ChurnZeroClient {
  constructor(private methods: any[]) {
    this.methods = methods;
  }

  static async embedScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const f = document.getElementsByTagName('script')[0];
      const j = document.createElement('script');
      j.async = true;
      j.src = url;
      j.crossOrigin = 'anonymous';
      f.parentNode?.insertBefore(j, f);
      j.onload = () => {
        resolve();
      };
      j.onerror = (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error,
      ) => {
        reject(
          new Error(
            `Failed to load ChurnZero script. ${
              typeof event === 'string' ? event : ''
            } ${error?.message ?? ''}`,
          ),
        );
      };
    });
  }

  static async connect(config: {
    url: string;
    apiKey: string;
    accountId: string;
    contactId: string;
  }): Promise<ChurnZeroClient> {
    await ChurnZeroClient.embedScript(config.url);
    const churnZero = (window as any).ChurnZero as any;
    if (!churnZero) {
      throw new Error(
        'ChurnZero object is not initialized by embedded script.',
      );
    }
    churnZero.push(['setAppKey', config.apiKey]);
    churnZero.push(['setContact', config.accountId, config.contactId]);
    return new ChurnZeroClient(churnZero);
  }

  trackEvent(...args: any[]): void {
    this.methods.push(['trackEvent', ...args]);
  }

  setAttribute(entity: string, attributes: Record<string, any>): void {
    this.methods.push(['setAttribute', entity, attributes]);
  }

  incrementAttribute(entity: string, name: string, value: number): void {
    this.methods.push(['incrementAttribute', entity, name, value]);
  }

  setModule(...args: any[]): void {
    this.methods.push(['setModule', ...args]);
  }

  urltracking(...args: any[]): void {
    this.methods.push(['urltracking', ...args]);
  }

  silent(...args: any[]): void {
    this.methods.push(['silent', ...args]);
  }

  open(...args: any[]): void {
    this.methods.push(['open', ...args]);
  }

  close(...args: any[]): void {
    this.methods.push(['close', ...args]);
  }

  stop(): void {
    this.methods.push(['stop']);
  }
}

export default ChurnZeroClient;
