import { UUID } from '@neo1/client';
import { CompanyUser } from '@neo1/client/lib/entities/user/types';
import { AccountingSegmentDefaultCoding } from '@neo1/client/lib/entities/segment/utils';
import { CollectionSortFilters } from '@neo1/core/utils/collections';
import { DefaultCodingByUserId, State, UserAddressesState } from './types';

export enum ActionTypeName {
  SET_COMPANY_USER_IDS = 'one/settings/users/SET_COMPANY_USER_IDS',
  SET_COMPANY_USER = 'one/settings/users/SET_COMPANY_USER',
  DELETE_COMPANY_USER = 'one/settings/users/DELETE_COMPANY_USER',
  SET_USER_DEFAULT_CODINGS = 'one/settings/users/SET_USER_DEFAULT_CODINGS',
  SET_USER_DEFAULT_CODING = 'one/settings/users/SET_USER_DEFAULT_CODINGs',
  UPDATE_USER_DEFAULT_CODING = 'one/settings/users/UPDATE_USER_DEFAULT_CODING',
  SET_USER_ADDRESSES = 'one/settings/users/SET_USER_ADDRESSES',
  SET_USER_ADDRESS = 'one/settings/users/SET_USER_ADDRESS',
  SET_USER_HOME_ADDRESS = 'one/settings/users/SET_USER_HOME_ADDRESS',
  DELETE_USER_ADDRESS = 'one/settings/users/DELETE_USER_ADDRESS',
  SET_USER_DOCUMENT = 'one/settings/user/SET_USER_DOCUMENT',
  SET_USER_DOCUMENTS = 'one/settings/users/GET_USER_DOCUMENTS',
  DELETE_USER_DOCUMENT = 'one/settings/users/DELETE_USER_DOCUMENT',
  SET_USER_VEHICLE = 'one/settings/users/SET_USER_VEHICLE',
  SET_USER_VEHICLES = 'one/settings/users/SET_USER_VEHICLES',
  DELETE_USER_VEHICLE = 'one/settings/users/DELETE_USER_VEHICLE',
  SET_SEARCH_FILTER = 'one/settings/users/SET_SEARCH_FILTER',
  SET_SORT_OPTIONS = 'one/settings/users/SET_SORT_OPTIONS',
}

type SetCompanyUserIds = {
  type: ActionTypeName.SET_COMPANY_USER_IDS;
  companyUserIds: State['companyUserIds'];
};

type SetCompanyUser = {
  type: ActionTypeName.SET_COMPANY_USER;
  companyUserId: CompanyUser['id'];
};

type DeleteCompanyUser = {
  type: ActionTypeName.DELETE_COMPANY_USER;
  companyUserId: CompanyUser['id'];
};

type SetUserDefaultCodings = {
  type: ActionTypeName.SET_USER_DEFAULT_CODINGS;
  defaultCodingByUserId: State['defaultCodingByUserId'];
};

type SetUserDefaultCoding = {
  type: ActionTypeName.SET_USER_DEFAULT_CODING;
  payload: {
    userId: CompanyUser['id'];
    defaultCoding: AccountingSegmentDefaultCoding;
  };
};

type UpdateUserDefaultCoding = {
  type: ActionTypeName.UPDATE_USER_DEFAULT_CODING;
  payload: {
    userId: CompanyUser['id'];
    defaultCoding: AccountingSegmentDefaultCoding;
  };
};

type SetUserAddresses = {
  type: ActionTypeName.SET_USER_ADDRESSES;
  payload: {
    userId: CompanyUser['id'];
    userAddresses: UserAddressesState;
  };
};

type SetUserAddress = {
  type: ActionTypeName.SET_USER_ADDRESS;
  payload: {
    userId: CompanyUser['id'];
    addressId: UUID;
  };
};

type SetUserHomeAddress = {
  type: ActionTypeName.SET_USER_HOME_ADDRESS;
  payload: {
    userId: CompanyUser['id'];
    home: UserAddressesState['home'];
  };
};

type DeleteUserAddress = {
  type: ActionTypeName.DELETE_USER_ADDRESS;
  payload: {
    userId: CompanyUser['id'];
    addressId: UUID;
  };
};

type SetUserDocument = {
  type: ActionTypeName.SET_USER_DOCUMENT;
  payload: {
    userId: CompanyUser['id'];
    docId: UUID;
  };
};

type SetUserDocuments = {
  type: ActionTypeName.SET_USER_DOCUMENTS;
  payload: {
    userId: CompanyUser['id'];
    docIds: UUID[];
  };
};

type DeleteUserDocument = {
  type: ActionTypeName.DELETE_USER_DOCUMENT;
  payload: {
    userId: CompanyUser['id'];
    docId: UUID;
  };
};

type SetUserVehicle = {
  type: ActionTypeName.SET_USER_VEHICLE;
  payload: {
    userId: CompanyUser['id'];
    vehicleId: string;
  };
};

type SetUserVehicles = {
  type: ActionTypeName.SET_USER_VEHICLES;
  payload: {
    userId: CompanyUser['id'];
    vehicleIds: string[];
  };
};

type DeleteUserVehicle = {
  type: ActionTypeName.DELETE_USER_VEHICLE;
  payload: {
    userId: CompanyUser['id'];
    vehicleId: string;
  };
};

type SetSearch = {
  type: ActionTypeName.SET_SEARCH_FILTER;
  search;
};

type SetSortOptions = {
  type: ActionTypeName.SET_SORT_OPTIONS;
  options: any;
};

export function setCompanyUserIds(
  companyUserIds: CompanyUser['id'][],
): SetCompanyUserIds {
  return {
    type: ActionTypeName.SET_COMPANY_USER_IDS,
    companyUserIds,
  };
}

export function setCompanyUser(
  companyUserId: CompanyUser['id'],
): SetCompanyUser {
  return {
    type: ActionTypeName.SET_COMPANY_USER,
    companyUserId,
  };
}

export function deleteCompanyUser(
  companyUserId: CompanyUser['id'],
): DeleteCompanyUser {
  return {
    type: ActionTypeName.DELETE_COMPANY_USER,
    companyUserId,
  };
}

export function setUserDefaultCodings(
  defaultCodingByUserId: DefaultCodingByUserId,
): SetUserDefaultCodings {
  return {
    type: ActionTypeName.SET_USER_DEFAULT_CODINGS,
    defaultCodingByUserId,
  };
}

export function setUserDefaultCoding(
  userId: CompanyUser['id'],
  defaultCoding: AccountingSegmentDefaultCoding,
): SetUserDefaultCoding {
  return {
    type: ActionTypeName.SET_USER_DEFAULT_CODING,
    payload: { userId, defaultCoding },
  };
}

export function updateUserDefaultCoding(
  userId: CompanyUser['id'],
  defaultCoding: AccountingSegmentDefaultCoding,
): UpdateUserDefaultCoding {
  return {
    type: ActionTypeName.UPDATE_USER_DEFAULT_CODING,
    payload: { userId, defaultCoding },
  };
}

export function setUserAddresses(
  userId: CompanyUser['id'],
  userAddressesState: UserAddressesState,
): SetUserAddresses {
  return {
    type: ActionTypeName.SET_USER_ADDRESSES,
    payload: {
      userId,
      userAddresses: userAddressesState,
    },
  };
}

export function setUserAddress(
  userId: CompanyUser['id'],
  addressId: UUID,
): SetUserAddress {
  return {
    type: ActionTypeName.SET_USER_ADDRESS,
    payload: { userId, addressId },
  };
}

export function setUserHomeAddress(
  userId: CompanyUser['id'],
  home: UserAddressesState['home'],
): SetUserHomeAddress {
  return {
    type: ActionTypeName.SET_USER_HOME_ADDRESS,
    payload: { userId, home },
  };
}

export function deleteUserAddress(
  userId: CompanyUser['id'],
  addressId: UUID,
): DeleteUserAddress {
  return {
    type: ActionTypeName.DELETE_USER_ADDRESS,
    payload: { userId, addressId },
  };
}

export function setUserDocuments(
  userId: CompanyUser['id'],
  docIds: UUID[],
): SetUserDocuments {
  return {
    type: ActionTypeName.SET_USER_DOCUMENTS,
    payload: { userId, docIds },
  };
}

export function setUserDocument(
  userId: CompanyUser['id'],
  docId: UUID,
): SetUserDocument {
  return {
    type: ActionTypeName.SET_USER_DOCUMENT,
    payload: { userId, docId },
  };
}

export function deleteUserDocument(
  userId: CompanyUser['id'],
  docId: UUID,
): DeleteUserDocument {
  return {
    type: ActionTypeName.DELETE_USER_DOCUMENT,
    payload: { userId, docId },
  };
}

export const setUserVehicle = (
  userId: CompanyUser['id'],
  vehicleId: string,
): SetUserVehicle => ({
  type: ActionTypeName.SET_USER_VEHICLE,
  payload: { userId, vehicleId },
});

export const setUserVehicles = (
  userId: CompanyUser['id'],
  vehicleIds: string[],
): SetUserVehicles => ({
  type: ActionTypeName.SET_USER_VEHICLES,
  payload: { userId, vehicleIds },
});

export const deleteUserVehicle = (
  userId: CompanyUser['id'],
  vehicleId: string,
): DeleteUserVehicle => ({
  type: ActionTypeName.DELETE_USER_VEHICLE,
  payload: { userId, vehicleId },
});

export function setSearchFilter(search): SetSearch {
  return {
    type: ActionTypeName.SET_SEARCH_FILTER,
    search,
  };
}

export function setColumnSortOptions(
  options: CollectionSortFilters,
): SetSortOptions {
  return {
    type: ActionTypeName.SET_SORT_OPTIONS,
    options,
  };
}

export type Actions =
  | SetCompanyUserIds
  | SetCompanyUser
  | DeleteCompanyUser
  | SetUserDefaultCodings
  | SetUserDefaultCoding
  | UpdateUserDefaultCoding
  | SetUserAddresses
  | SetUserAddress
  | SetUserHomeAddress
  | DeleteUserAddress
  | SetUserDocument
  | SetUserDocuments
  | DeleteUserDocument
  | SetUserVehicle
  | SetUserVehicles
  | DeleteUserVehicle
  | SetSearch
  | SetSortOptions;
