import { getConfigValue } from '../../../config';
import { EntityType, StandardEntityAttributes } from './types';
import ChurnZeroClient from './client';

let isChurnZeroInstanceInitialized: boolean = false;

export const getIsChurnZeroInstanceInitialized = () =>
  isChurnZeroInstanceInitialized;
export const setIsChurnZeroInstanceInitialized = (value: boolean) => {
  isChurnZeroInstanceInitialized = value;
};

let churnZeroClient: ChurnZeroClient = null;

export async function startChurnZero(
  accountExternalId: string,
  contactExternalId: string,
) {
  const JsScriptUrl = getConfigValue('churnZeroJsScriptUrl');
  const APIKey = getConfigValue('churnZeroAPIKey');
  if (JsScriptUrl && APIKey && !getIsChurnZeroInstanceInitialized()) {
    churnZeroClient = await ChurnZeroClient.connect({
      url: JsScriptUrl,
      apiKey: APIKey,
      accountId: accountExternalId,
      contactId: contactExternalId,
    });
    setIsChurnZeroInstanceInitialized(true);
  }
}

export const stopChurnZero = () => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.stop();
    setIsChurnZeroInstanceInitialized(false);
  }
};

export const trackEvent = (
  name: string,
  description?: string,
  quantity?: number,
  customFields?: Record<string, any>,
) => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.trackEvent(name, description, quantity, customFields);
  }
};

export const setAttribute = <E extends EntityType>(
  entity: E,
  attributes: Partial<StandardEntityAttributes[E]>,
) => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.setAttribute(entity, attributes);
  }
};

export const incrementAttribute = <E extends EntityType>(
  entity: E,
  name: keyof StandardEntityAttributes[E],
  value: number,
) => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.incrementAttribute(entity, name.toString(), value);
  }
};

export const setModule = (module: string) => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.setModule(module);
  }
};

export const urltracking = (enabled: boolean) => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.urltracking(enabled);
  }
};

export const silent = (enabled: boolean) => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.silent(enabled);
  }
};

export const successPanelOpen = () => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.open();
  }
};

export const successPanelClose = () => {
  if (getIsChurnZeroInstanceInitialized()) {
    churnZeroClient.close();
  }
};
