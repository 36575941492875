import uniq from 'lodash/uniq';
import { Actions, AutomationRuleActions } from './actions';
import { AutomationRulesState } from './types';

const initialState: AutomationRulesState = {
  ids: [],
};

const automationRulesReducer = (
  state: AutomationRulesState = initialState,
  action: AutomationRuleActions = null,
): AutomationRulesState => {
  switch (action?.type) {
    case Actions.SET_AUTOMATION_RULES:
      return {
        ids: action.ids,
      };

    case Actions.SET_AUTOMATION_RULE:
      return {
        ids: uniq([...state.ids, action.id]),
      };

    case Actions.DELETE_AUTOMATION_RULE:
      return {
        ids: state.ids.filter((id) => id !== action.id),
      };

    default:
      return state;
  }
};
export default automationRulesReducer;
