import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'modalTitle',
    defaultMessage: '{service} configuration',
  },
  modalSubmitTitle: {
    id: 'modalSubmitTitle',
    defaultMessage: 'Confirm',
  },
  modalCloseTitle: {
    id: 'modalCloseTitle',
    defaultMessage: 'Close',
  },
  modalCancelTitle: {
    id: 'modalCancelTitle',
    defaultMessage: 'Cancel connection',
  },
  formTitle: {
    id: 'formTitle',
    defaultMessage:
      'Congratulations your Neo1 account is now connected to {service}!',
  },
  formDescription1: {
    id: 'formDescription1',
    defaultMessage:
      'To finalize your setup, select the tax codes to be used for international and zero rated domestic expenses.',
  },
  internationalTax: {
    id: 'internationalTax',
    defaultMessage: 'Non-domestic tax',
  },
  internationalTaxInfo: {
    id: 'internationalTaxInfo',
    defaultMessage:
      'This is the tax code used for international / non domestic transactions.',
  },
  zeroRatedTax: {
    id: 'zeroRatedTax',
    defaultMessage: 'Domestic 0% tax',
  },
  zeroRatedTaxInfo: {
    id: 'zeroRatedTaxInfo',
    defaultMessage:
      'This is the tax code used for domestic transactions where no tax is reimbursable. Either no tax is applicable, or evidence used to substantiate a reimbursement is not available.',
  },
  mileageZeroRatedTax: {
    id: 'mileageZeroRatedTax',
    defaultMessage: 'Mileage 0% tax',
  },
  mileageZeroRatedTaxInfo: {
    id: 'mileageZeroRatedTaxInfo',
    defaultMessage: 'This is the tax code used for Mileage transactions.',
  },
  naTax: {
    id: 'naTax',
    defaultMessage: 'No tax',
  },
  naTaxInfo: {
    id: 'naTaxInfo',
    defaultMessage:
      'This is the tax code used when tax is not applicable for the transaction.',
  },
  reportedTaxAmountCode: {
    id: 'reportedTaxAmountCode',
    defaultMessage: 'Tax code for manual entry',
  },
  reportedTaxAmountCodeInfo: {
    id: 'reportedTaxAmountCodeInfo',
    defaultMessage:
      'When user enters the tax amount this will be the tax code used.',
  },
  selectPlaceholder: {
    id: 'selectPlaceholder',
    defaultMessage: '- Select a value -',
  },
  loadingConnectionTitle: {
    id: 'loadingConnectionTitle',
    defaultMessage:
      'We have successfully established connection with your accounting system',
  },
  loadingConnectionDescription: {
    id: 'loadingConnectionDescription',
    defaultMessage: 'Please wait while we retrieve your accounts and tax codes',
  },
  notifyWarningMessage: {
    id: 'notifyWarningMessage',
    defaultMessage:
      'Beware! Interrupting the mapping process now will permanently erase all imported segment values and tax codes. Connection to your accounting software will be terminated.',
  },
  notifySuccessCancelMessage: {
    id: 'notifySuccessCancelMessage',
    defaultMessage:
      'Connection successfully canceled for {connectedServiceName}',
  },
  notifyErrorSubmitMessage: {
    id: 'notifyErrorSubmitMessage',
    defaultMessage: 'Could not save business configuration: {error}',
  },
  notifySuccessSubmitMessage: {
    id: 'notifySuccessSubmitMessage',
    defaultMessage: 'Business configuration successfully saved.',
  },
  taxConfigurationFormSubmitBtnMsg: {
    id: 'TaxConfigurationFormSubmitBtnMsg',
    defaultMessage: 'Confirm',
  },
  taxConfigurationFormTitle: {
    id: 'taxConfigurationFormTitle',
    defaultMessage: 'Defaults',
  },
  taxConfigurationFormSuccessMsg: {
    id: 'taxConfigurationFormSuccessMsg',
    defaultMessage: 'Tax configuration successfully saved',
  },
});

export default messages;
